import React, { useEffect } from 'react';
import { Typography, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { black, silverChalice, redOrange, tundora, pippin, alto } from '../../themes/colors';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { fetchProfileDetails } from '../../store/actions';
import { animateScroll as scroll } from 'react-scroll';
import PersonalData from './PersonalData';
import ShippingAddress from './ShippingAddress';
import Subscription from './Subscription';
import Payment from './Payment';
import Loading from '../../components/Loading';

import { Skeleton } from '@material-ui/lab';

import { isEqual } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    marginBottom: '2rem',
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  subtitle: {
    color: tundora,
  },
  previousBoxTitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  previousBoxsubtitle: {
    color: tundora,
    fontWeight: 'bold',
  },
  trackingBox: {
    background: pippin,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  contentBox: {
    padding: '1.5rem 2rem 0',
    flex: 1,
  },
  button: {
    color: redOrange,
    padding: '0',
    marginBottom: '1.5rem',
  },
  previousBox: {
    marginTop: '1rem',
    padding: '2rem',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    '& img': {
      position: 'relative',
      bottom: '-1rem',
      right: '0rem',
      width: '14rem',
      [theme.breakpoints.up('md')]: {
        width: ' 20rem',
        right: '5rem',
        bottom: '-1.5rem',
      },
    },
  },
  boxTitle: {
    color: silverChalice,
  },
  boxSubtitle: {
    color: black,
    fontSize: '1.2em',
  },
  cancelledAccount: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    margin: '0 auto',
    '& p': {
      textAlign: 'center',
      margin: '1rem 0',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
  },
  skeletonText: {
    marginTop: '2em',
    marginBottom: '2em',
  },
}));

const Profile = ({
  onFetchProfileDetails,
  customer,
  shippingAddress,
  subscription,
  dashboardInvoiceDTO,
  paymentProfile,
  navigateTo,
  loading,
  tenantName,
}) => {
  const classes = useStyles();
  const scrollTop = () => scroll.scrollToTop();

  useEffect(() => {
    scrollTop();
    onFetchProfileDetails();
  }, [onFetchProfileDetails]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <div className={classes.trackingBox}>
          <div className={classes.contentBox}>
            <Typography className={classes.title} gutterBottom>
              Perfil
            </Typography>
            <Typography className={classes.subtitle} gutterBottom>
              Mig, aqui você pode alterar seus dados pessoais, endereços, planos e suas formas de
              pagamento sempre que precisar!
            </Typography>
            <Typography className={classes.subtitle} gutterBottom>
              Ah, e não esquece de dar uma olhadinha no seu perfil de beleza!
            </Typography>
          </div>
          <div className={classes.imageBox}>
            <img
              src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/girl_pencil.png"
              alt=""
            />
          </div>
        </div>

        {!loading ? (
          <div className={classes.previousBox}>
            <PersonalData
              customer={customer}
              navigateTo={navigateTo}
              loading={loading}
              tenantName={tenantName}
            />
            <ShippingAddress
              shippingAddress={shippingAddress}
              navigateTo={navigateTo}
              loading={loading}
            />
            {isEqual(subscription?.status, 'CANCELED') || !subscription?.status ? (
              <div className={classes.cancelledAccount}>
                <Typography gutterBottom>
                  Na UAUBox você recebe os produtos de beleza escolhidos para você todos os meses.
                  Além de um conteúdo muito especial, preparado exclusivamente para as assinantes.
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigateTo('/checkout')}
                >
                  ASSINE AGORA
                </Button>
              </div>
            ) : (
              <div>
                <Subscription
                  subscription={subscription}
                  invoice={dashboardInvoiceDTO}
                  navigateTo={navigateTo}
                  loading={loading}
                />
                <Payment
                  paymentProfile={paymentProfile}
                  customer={customer}
                  navigateTo={navigateTo}
                  loading={loading}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={classes.previousBox}>
            <div className={classes.skeletonText}>
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
            </div>
            <Divider />
            <div className={classes.skeletonText}>
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
            </div>
            <Divider />
            <div className={classes.skeletonText}>
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
            </div>
            <Divider />
            <div className={classes.skeletonText}>
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
            </div>
          </div>
        )}
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { tenantName } = state.auth;
  const {
    customer,
    shippingAddress,
    subscription,
    dashboardInvoiceDTO,
    paymentProfile,
    loading,
  } = state.profile;
  return {
    customer,
    shippingAddress,
    subscription,
    dashboardInvoiceDTO,
    paymentProfile,
    loading,
    tenantName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchProfileDetails: () => dispatch(fetchProfileDetails()),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
