import { put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import { initialize, reset, change } from 'redux-form';
import * as fromTypes from '../types';
import * as fromActions from '../actions';
import { parseAddressFromApi, buildProfilePayload } from '../../models/profileBuilders';
import { toForm } from '../../models/customer';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { logException, logEvent } from '../../components/utils/GoogleAnalytics';

import { getTenantToken } from '../services/auth';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;

const getAddressFormState = (state) => state?.form?.shippingAddressForm;
const getProfileFromState = (state) => state?.profile?.customer;
const getBeautyProfileAnswerFromState = (state) => state?.profile?.beautyProfileAnswer;
const getOrderIdState = (state) => state?.profile?.orderId;

export function* fetchAddressByZipcode({ zipcode }) {
  try {
    const response = yield axios.get(`https://viacep.com.br/ws/${zipcode}/json/`, {
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
      },
    });

    if (response.status === 200) {
      const dataAddress = response.data;
      if (dataAddress.erro) {
        toast.error('CEP não encontrado.');
        logException(`Fetch Address ZipCode error: ${dataAddress}`, false);
        yield put(change('shippingAddressForm', 'validated', false));
      } else {
        const shippingAddress = parseAddressFromApi({ validated: true, ...response.data });
        const formState = yield select(getAddressFormState);
        const formValues = formState?.values;
        yield put(initialize('shippingAddressForm', { ...formValues, ...shippingAddress }));
      }
      yield put(fromActions.fetchAddressByZipcodeSuccess(dataAddress));
    }
  } catch (error) {
    logException(`fetchAddressByZipcode ${zipcode}: ${error.message}`, false);
    console.error(error);
    yield put(fromActions.fetchAddressByZipcodeError());
  }
}

export function* fetchProfile() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/profile`);

    if (response.status === 200) {
      yield put(fromActions.fetchProfileSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`fetchProfile: ${error.message}`, false);
    yield put(fromActions.fetchProfileError(error));
  }
}

export function* updateProfile({ profile }) {
  try {
    const { id } = yield select(getProfileFromState);
    const profileData = buildProfilePayload(profile, id);
    const TENANT = yield select(getTenantToken);

    const response = yield axios.put(`${BASE_API}/${TENANT}/profile`, profileData);

    if (response.status === 200) {
      logEvent(
        'Update Profile Success',
        'dashboard.profile.updatesucess',
        'User update your profile sucessfully'
      );
      toast.success('Perfil atualizado com sucesso.');
      yield put(push('/perfil'));
      yield put(fromActions.updateProfileSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`updateProfile ${profile}: ${error.message}`, false);
    yield put(fromActions.updateProfileError(error));
  }
}

export function* fetchProfileAnswers() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/profile/answers`);

    if (response.status === 200) {
      yield put(fromActions.fetchProfileAnswersSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchProfileAnswers: ${error.message}`, false);
    yield put(fromActions.fetchProfileAnswersError(error));
  }
}

export function* fetchProfileAnswer({ id }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/profile/answers/${id}`);

    if (response.status === 200) {
      yield put(fromActions.fetchProfileAnswerSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchProfileAnswer ${id}: ${error.message}`, false);
    yield put(fromActions.fetchProfileAnswerError(error));
  }
}

export function* fetchProfileDetails() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/profile/alldetails`);

    if (response.status === 200) {
      const customer = toForm(response.data?.customer);
      yield put(fromActions.fetchProfileDetailsSuccess(response.data));
      yield put(initialize('customerForm', customer));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchProfileDetails: ${error.message}`, false);
    yield put(fromActions.fetchProfileDetailsError(error));
  }
}

export function* createShippingAddress({ shippingAddress }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.post(`${BASE_API}/${TENANT}/shipping-addresses`, {
      ...shippingAddress,
    });

    if (response.status === 200) {
      toast.success('Endereço adicionado com sucesso.');
      yield put(push('/perfil/enderecos'));
      yield put(fromActions.createShippingAddressSuccess());
    }
  } catch (error) {
    console.error(error);
    logException(`createShippingAddress ${shippingAddress}: ${error.message}`, true);
    yield put(fromActions.createShippingAddressError(error));
  }
}

export function* fetchShippingAddresses() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/shipping-addresses`);

    if (response.status === 200) {
      yield put(fromActions.fetchShippingAddressesSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchShippingAddresses: ${error.message}`, true);
    yield put(fromActions.fetchShippingAddressesError(error));
  }
}

export function* fetchShippingAddress({ id }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/shipping-addresses/${id}`);

    if (response.status === 200) {
      yield put(fromActions.fetchShippingAddressSuccess(response.data));
      yield put(initialize('shippingAddressForm', response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchShippingAddress ${id}: ${error.message}`, false);
    yield put(fromActions.fetchShippingAddressError(error));
  }
}

export function* updateShippingAddress({ shippingAddress }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.put(
      `${BASE_API}/${TENANT}/shipping-addresses/${shippingAddress?.id}`,
      shippingAddress
    );

    if (response.status === 200) {
      logEvent(
        'Update Address Success',
        'dashboard.address.updatesucess',
        'User update your Shipping Address sucessfully'
      );
      toast.success('Endereço atualizado com sucesso.');
      yield put(push('/perfil/enderecos'));
      yield put(fromActions.updateShippingAddressSuccess(shippingAddress));
    }
  } catch (error) {
    console.error(error);
    toast.error(error.message);
    logException(`updateShippingAddress ${shippingAddress}: ${error.message}`, false);
    yield put(fromActions.updateShippingAddressError(error));
  }
}

export function* deleteShippingAddress({ id }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.delete(`${BASE_API}/${TENANT}/shipping-address/${id}`);

    if (response.status === 200) {
      logEvent(
        'Delete Address Success',
        'dashboard.address.deleteaddresssuccess',
        'User delete your Shipping Address sucessfully'
      );
      yield put(fromActions.updateShippingAddressSuccess(response.data));
      yield put(fromActions.fetchShippingAddresses());
      toast.success('Endereço excluído com sucesso.');
    }
  } catch (error) {
    console.error(error);
    toast.error(error.message);
    logException(`deleteShippingAddress ${id}: ${error.message}`, false);
    yield put(fromActions.deleteShippingAddressError(error));
  }
}

export function* fetchBoxOrders() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/box-orders`);

    if (response.status === 200 || response.status === 204) {
      yield put(fromActions.fetchBoxOrdersSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchBoxOrders: ${error.message}`, true);
    yield put(fromActions.fetchBoxOrdersError(error));
  }
}

export function* fetchBoxOrder({ id }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/box-orders/${id}`);

    if (response.status === 200) {
      yield put(fromActions.fetchBoxOrderSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchBoxOrder ${id}: ${error.message}`, false);
    yield put(fromActions.fetchBoxOrderError(error));
  }
}

export function* fetchFeedbackQuestionsByOrderId({ orderId }) {
  try {
    const response = yield axios.get(`${BASE_API}/feedback/orders/${orderId}/questions`);

    if (response.status === 200) {
      yield put(fromActions.fetchFeedbackQuestionsByOrderIdSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchFeedbackQuestionsByOrderId ${orderId}: ${error.message}`, false);
    yield put(fromActions.fetchFeedbackQuestionsByOrderIdError(error));
  }
}

export function* createFeedback({ orderId, feedback, page }) {
  try {
    let response = yield axios.post(`${BASE_API}/orders/${orderId}/feedback`, feedback);

    if (response.status === 201) {
      logEvent(
        'Create Feedback Success',
        'dashboard.feedback.createsuccess',
        'User create feedback sucessfully'
      );
      response.data.page = page;
      yield put(fromActions.createFeedbackSuccess(response.data));
      yield put(fromActions.setFeedbackSubmit(true));
      yield put(reset('feedbackForm'));
    }
  } catch (error) {
    console.error(error);
    logException(
      `createFeedback: ${orderId}, Feedback: ${feedback} error: ${error.message}`,
      false
    );
    toast.error(error.message);
    yield put(fromActions.createFeedbackError(error));
  }
}

export function* updateBeautyProfile({ payload }) {
  try {
    const { shouldGoNext } = payload;

    const TENANT = yield select(getTenantToken);
    const answersUpdate = yield select(getBeautyProfileAnswerFromState);
    const idProfile = sessionStorage.getItem('idProfile');
    const response = yield axios.put(
      `${BASE_API}/${TENANT}/profile/${idProfile}/answers`,
      answersUpdate
    );

    if (response.status === 200 && !shouldGoNext) {
      toast.success('Perfil de beleza atualizado com sucesso.');
      yield put(push('/perfil/perfil-de-beleza'));
    }
  } catch (error) {
    console.error(error);
    logException(`updateBeautyProfile: ${error.message}`, false);
    toast.error('Houve um erro inesperado, tente novamente.');
  }
}

export function* setDeliveryFeedback({ payload }) {
  try {
    const orderId = yield select(getOrderIdState);
    let response = yield axios.post(`${BASE_API}/orders/${orderId}/delivery-feedback`, payload);
    logEvent(
      'Create Delivery Feedback Success',
      'delivery.feedback.success',
      'User create delivery feedback sucessfully'
    );

    if (!response.data.success && response.data.message === 'alreadyAnswered') {
      toast.warning('Você já enviou o feedback da última entrega.');
    } else {
      toast.success('Feedback enviado com sucesso.');
    }

    yield put(fromActions.setDeliveryFeedbackSuccess(response.data));
    yield put(reset('deliveryFeedbackForm'));
    yield put(push('/dashboard'));
  } catch (error) {
    console.error(error);
    logException(`createDeliveryFeedback: ${payload}, error: ${error.message}`, false);
    toast.error(error.message);
    yield put(fromActions.setDeliveryFeedbackError(error));
  }
}

export function* fetchOrderId() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/orders/most-recent`);
    if (response.data !== '') {
      yield put(fromActions.fetchOrderIdSuccess(response.data));
    } else {
      toast.error('Não encontramos uma entrega para avaliação.');
      yield put(push('/dashboard'));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`fetchOrderId: ${error.message}`, false);
    yield put(fromActions.fetchOrderIdError(error));
    yield put(push('/dashboard'));
  }
}

export function* watchProfile() {
  yield takeLatest(fromTypes.FETCH_ADDRESS_BY_ZIPCODE, fetchAddressByZipcode);
  yield takeLatest(fromTypes.FETCH_PROFILE, fetchProfile);
  yield takeLatest(fromTypes.UPDATE_PROFILE, updateProfile);
  yield takeLatest(fromTypes.FETCH_PROFILE_ANSWERS, fetchProfileAnswers);
  yield takeLatest(fromTypes.FETCH_PROFILE_ANSWER, fetchProfileAnswer);
  yield takeLatest(fromTypes.FETCH_PROFILE_DETAILS, fetchProfileDetails);
  yield takeLatest(fromTypes.CREATE_SHIPPING_ADDRESS, createShippingAddress);
  yield takeLatest(fromTypes.FETCH_SHIPPING_ADDRESSES, fetchShippingAddresses);
  yield takeLatest(fromTypes.FETCH_SHIPPING_ADDRESS, fetchShippingAddress);
  yield takeLatest(fromTypes.UPDATE_SHIPPING_ADDRESS, updateShippingAddress);
  yield takeLatest(fromTypes.DELETE_SHIPPING_ADDRESS, deleteShippingAddress);
  yield takeLatest(fromTypes.FETCH_BOX_ORDERS, fetchBoxOrders);
  yield takeLatest(fromTypes.FETCH_BOX_ORDER, fetchBoxOrder);
  yield takeLatest(fromTypes.FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID, fetchFeedbackQuestionsByOrderId);
  yield takeLatest(fromTypes.CREATE_FEEDBACK, createFeedback);
  yield takeLatest(fromTypes.UPDATE_BEAUTY_PROFILE, updateBeautyProfile);
  yield takeLatest(fromTypes.SET_DELIVERY_FEEDBACK, setDeliveryFeedback);
  yield takeLatest(fromTypes.FETCH_ORDER_ID, fetchOrderId);
}
