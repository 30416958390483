const planPeriod = [
  {
    period: 'MONTH',
    name: 'Testei (Mensal)',
    nameDefault: 'Mensal',
  },
  {
    period: 'QUARTERLY',
    name: 'Amei (Trimestral)',
    nameDefault: 'Trimestral',
  },
  {
    period: 'QUARTERLY_INSTALLMENT',
    name: 'Amei (Trimestral)',
    nameDefault: 'Trimestral',
  },
  {
    period: 'BIANNUAL',
    name: 'Viciei (Semestral)',
    nameDefault: 'Semestral',
  },
  {
    period: 'BIANNUAL_INSTALLMENT',
    name: 'Viciei (Semestral)',
    nameDefault: 'Semestral',
  },
  {
    period: 'ANNUAL',
    name: 'Não Vivo Sem (Anual)',
    nameDefault: 'Anual',
  },
  {
    period: 'ANNUAL_INSTALLMENT',
    name: 'Não Vivo Sem (Anual)',
    nameDefault: 'Anual',
  },
];

export const getPlanPeriod = (period, tenant) => {
  let planName;
  if (tenant === 'uaubox') {
    planName = planPeriod.find((i) => i.period === period)?.name;
  } else {
    planName = planPeriod.find((i) => i.period === period)?.nameDefault;
  }
  return planName;
};

export const getPlanCost = ({ amountInstallment } = {}) =>
  amountInstallment ? amountInstallment : 0;

export const toMoney = (value) =>
  value ? (value / 100).toFixed(2).replace('.', ',') : Number(0).toFixed(2);

export function getPlanName(item) {
  const status = item.recurrencePeriod;
  switch (status) {
    case 'MONTH':
      return `Plano Mensal - R$${toMoney(item.amountInstallment)}/mês`;
    case 'QUARTERLY':
      return `Plano Trimestral - R$${toMoney(item.amountInstallment)}/mês`;
    case 'BIANNUAL':
      return `Plano Semestral - R$${toMoney(item.amountInstallment)}/mês`;
    case 'ANNUAL':
      return `Plano Anual - R$${toMoney(item.amountInstallment)}/mês`;
    default:
      return '';
  }
}

export function getPlanNameAndAmount(amount, recurrencePeriod) {
  switch (recurrencePeriod) {
    case 'MONTH':
      return `Plano Mensal - R$${toMoney(amount)}/mês`;
    case 'QUARTERLY':
    case 'QUARTERLY_INSTALLMENT':
      return `Plano Trimestral - R$${toMoney(amount)}/mês`;
    case 'BIANNUAL_INSTALLMENT':
    case 'BIANNUAL':
      return `Plano Semestral - R$${toMoney(amount)}/mês`;
    case 'ANNUAL':
    case 'ANNUAL_INSTALLMENT':
      return `Plano Anual - R$${toMoney(amount)}/mês`;
    default:
      return '';
  }
}
